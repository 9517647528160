import { createSelector } from 'reselect';
import { find } from '../../lib/nodash';

const makeSelectPaintCollectionByShopifyId = () => {
  return createSelector(
    (state) => state.product.paintCollections,
    (_, shopifyId) => shopifyId,
    (paintCollections, shopifyId) => {
      return find({ shopifyId }, paintCollections);
    }
  );
};

export default makeSelectPaintCollectionByShopifyId;
