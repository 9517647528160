import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { get, map, flatten } from '../../lib/nodash';

import makeSelectPaintCollectionByProductId from '../../state/product/makeSelectPaintCollectionByProductId';
import makeSelectPaintCollectionByShopifyId from '../../state/product/makeSelectPaintCollectionByShopifyId';
import makeSelectShopifyProductByShopifyId from '../../state/product/makeSelectShopifyProductByShopifyId';
import { findSampleVariant, findMemoVariant } from '../../lib/product';

const useShopifyProduct = ({ product, collection }) => {
  const source = get('item', product) || collection || product;

  const selectPaintCollectionByProductId = useMemo(
    makeSelectPaintCollectionByProductId,
    []
  );
  const selectPaintCollectionByShopifyId = useMemo(
    makeSelectPaintCollectionByShopifyId,
    []
  );
  const selectShopifyProductByShopifyId = useMemo(
    makeSelectShopifyProductByShopifyId,
    []
  );

  const productCollection =
    useSelector((state) =>
      collection
        ? selectPaintCollectionByShopifyId(state, get('shopifyId', collection))
        : selectPaintCollectionByProductId(state, get('shopifyId', source))
    ) || collection;

  const collectionProduct = useSelector((state) =>
    selectShopifyProductByShopifyId(state, get('shopifyId', source))
  );

  const variants = collection
    ? flatten(map('variants', get('products', collection)))
    : get('variants', collectionProduct);

  const sampleVariant =
    findMemoVariant(variants) || findSampleVariant(variants);

  return {
    variants,
    sampleVariant,
    productCollection,
    entity: source,
  };
};

export default useShopifyProduct;
