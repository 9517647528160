import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { get } from '../../lib/nodash';
import { useLocation } from '@reach/router';

import ButtonAddToCart from '../ButtonAddToCart';
import useProductFromContextOrPicker from './useProductFromContextOrPicker';
import { track } from '../../lib/analytics';
import { SAMPLE_ADDED } from '../../lib/analytics/segmentActions';

const DynaimcButtonAddSample = ({
  product,
  collection,
  label = 'Get Sample',
}) => {
  const { sampleVariant } = useProductFromContextOrPicker({
    product,
    collection,
  });
  const location = useLocation();

  const handleAdded = useCallback(() => {
    track(SAMPLE_ADDED, {
      product: product.title,
      pdp: location.pathname !== '/paint',
      cdp: location.pathname === '/paint',
      location: window.location.pathname,
    });
  }, [track, SAMPLE_ADDED]);

  return (
    <ButtonAddToCart
      primary
      label={label}
      variantId={get('shopifyId', sampleVariant)}
      onAdded={handleAdded}
    />
  );
};

DynaimcButtonAddSample.propTypes = {
  product: PropTypes.object,
  collection: PropTypes.object,
  label: PropTypes.string,
};

export default memo(DynaimcButtonAddSample);
